<script>
import Layout from "../../layouts/main";



/**
 * Dashboard component
 */
export default {
  components: {
    Layout
  },
  data() {
    return {
     
    };
  }
};
</script>

<template>
  <layout>
   
   <b-card>
    <b-col>
      <h5>Payed version only</h5>
    </b-col>
   </b-card>
  </layout>
</template>